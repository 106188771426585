import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  COLOR, MAX_TABLET_WIDTH,
} from '../../constants';
import SymbolsCombined from './SymbolsCombined';
import SymbolNote from './SymbolNote'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  /* min-height: 5rem; */
  cursor: pointer;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    flex-direction: column;
  }
`;

const Box = styled.div`
  padding: 1rem;
  border: 1px solid white;
  width: calc(100% - 1rem);
  white-space: pre-wrap;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    width: 100%;
  }

`;
const Left = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 50%;
`



const SymbolsContainer = styled.div`
  flex: 0 0 2rem;
  padding-right: 0.5rem;
  background: #444;
  padding-left: 0.5rem;
  border: 1px solid white;
  border-right: none;
`

const QuestionContainer = styled.div`
  flex: 0 0 calc(100% - 2rem);
`

const Question = styled.div`
  padding: 1rem;
  padding-right: 2rem; // make it wrap
  /* padding-bottom: 2.5rem; */
  border: 1px solid white;
  width: calc(100% - 1rem);
  white-space: pre-wrap;
  position: relative;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    width: 100%;
  }

  position: relative;
`;

type BottomProps = {
  show: boolean;
}
const Bottom = styled.div<BottomProps>`
  padding: 1rem;
  border: 1px solid white;
  width: calc(100% - 1rem);
  white-space: pre-wrap;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    width: 100%;
  }

  display: ${props => props.show ? 'visible' : 'none' };
  background-color: ${props => props.show ? '#303030' : '#121212'};
  position: relative;
  padding-bottom: 2rem;
`;


const Tag = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  background-color: #777;
  padding: 2px 6px;
  margin-right: 0.5rem;
  border-radius: 4px;
`;
const Tags = styled.div`
  display: inline-flex;
  margin-left: 0.5rem;
`;

const Count = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  border: 1px solid white;
  padding: 2px 6px;
  border-radius: 4px;
`;
const SectionHeading = styled.span`
  margin-bottom: 0.5rem;
  display: inline-block;
`

const getShow = (clicks, show) => {
  if (clicks === 0) {
    return false;
  }
  return show;
}

type AbbrevProps = {
  cell?: boolean;
  color?: string;
}
export const Abbrev = styled.span<AbbrevProps>`
  font-size: ${p => {
    return p.cell ? '0.7rem' : `0.9rem`;
  }};
  border: 1px solid ${p => p.color};
  border-radius: 3px;
  padding: 2px 6px;
  margin-left: 0.5rem;
  margin-right: ${p => {
    return p.cell ? 0 : `0.5rem`;
  }};
  margin-bottom: ${p => {
    return p.cell ? 0 : `1rem`;
  }};
`;

type CsvRowProps = {
  row: { data: any, dataRowNumber: number, countViewed: number };
  clicks: number;
  range: string;

  incrementClicks: () => void;
  decrementClicks: () => void;
  incrementGoogleCounter: (dataRowNumber: number) => void;
  updateGoogleSheet: (data: {[key: string]: string}) => void;
}

const hasTags = (str) => {
  if (!str || !str.length) {
    return false;
  }
  return true
}

const getTags = (arrStr) => {
  const result = arrStr.split(',');
  return result;
}
export function CsvRow (props: CsvRowProps) {
  const {
    row,
    clicks,
    range,

    incrementClicks,
    decrementClicks,
    incrementGoogleCounter,
    updateGoogleSheet,
  } = props;
  const [show, setShow] = useState(false);

  const display = getShow(clicks, show);

  const handleClick = (e) => {
    if (!show) {
      const dataRowNumber = row.dataRowNumber;
      incrementGoogleCounter(dataRowNumber);
    }

    setShow(!show);
    if (display) {
      decrementClicks();
    } else {
      incrementClicks();
    }
  }

  const handleSymbolEdit = (data) => {
    updateGoogleSheet(data);
  }

  const {data} = row;

  const symbolData = {
    difficulty: data.difficulty,
    edit: data.edit,
    note: data.note,
  }

  return (
    <Container>
      <Left>
        <SymbolsContainer>
          <SymbolsCombined
            data={symbolData}
            handleSymbolEdit={handleSymbolEdit}
          />
        </SymbolsContainer>

        <QuestionContainer>
          <Question onClick={handleClick}>
            <SectionHeading>
              {row.data.sectionHeading}
            </SectionHeading>

            <Abbrev color={row.data.color} cell>{row.data.system}</Abbrev>

            {hasTags(row.data.tags) && (
              <Tags>{getTags(row.data.tags).map((t, i) => {
                const key = t + i;
                return (<Tag key={key}>{t}</Tag>)
              })}</Tags>
            )}


            <Count>
              {row.countViewed}
            </Count>

          </Question>
        </QuestionContainer>

      </Left>

      <Bottom show={display}>
        {row.data.text}
      </Bottom>

    </Container>
  )
}
