import React from 'react';
import PageCsv from '../components/Csv/PageCsvSummaries';

const SPREADSHEET_ID = '1TkTG1AL7mLVM124q5Ftz76tSzs6bf4FACLrPkoHxUS0';
const SHEET_ID = 'summaries';
const TITLE = 'Summaries';
const RANGE = 'A1:O';

export default function Page () {
  const RANGE_COMPLETE = SHEET_ID ? `${SHEET_ID}!${RANGE}` : RANGE;
  return (
    <PageCsv
      title={TITLE}
      spreadsheetId={SPREADSHEET_ID}
      sheetId={SHEET_ID}
      range={RANGE_COMPLETE}
    />
  )

}
